<template>
  <a-layout>
    <div class="nav">
      <Breadcrumb />
    </div>
    <div class="main__container">
      <div class="account">
        价值包收益：
        <span class="account__unit">
          ¥
          <span class="account__num">{{ balance }}</span>
        </span>
        <a-space class="operation">
          <a @click="onReceiptAndPayRecords">查看收付款记录</a>
        </a-space>
      </div>
    </div>
  </a-layout>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import { checkAccountBalance } from "@/services/PayService";

export default {
  name: "AccountBalanceOperation",
  components: {
    Breadcrumb
  },
  data() {
    return {
      balance: 0
    };
  },
  mounted() {
    // this.loadAccountBalance();
  },
  methods: {
    //查看收付款记录
    onReceiptAndPayRecords() {
      this.$router.push({ name: "ReceiptAndPayRecords" });
    },
    // 充值
    onRecharge() {
      this.$router.push({ name: "Recharge" });
    },
    // 查询账户余额
    loadAccountBalance() {
      checkAccountBalance(localStorage.getItem("corporationId")).then(resp => {
        if (resp.data.code === "SUCCESS") {
          const data = resp.data.data;
          if (data.acctInfo && data.acctInfo.length > 0) {
            this.balance = data.acctInfo[0].balanceAmt;
          }
        }
      });
    },
    // 跳转到查看明细页面
    toCountDetail() {
      this.$router.push({ name: "AmountDetail" });
    },
    // 跳转查看手续费账户明细
    toCommissionDetail() {
      this.$router.push({ name: "CommissionAccountDetail" });
    }
  }
};
</script>

<style scoped>
.main__container {
  min-height: 88vh;
  margin: 24px;
  background-color: white;
  box-sizing: border-box;
  padding: 20px;
}

.account {
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
}

.account__unit {
  font-weight: bold;
  transform: translateY(2px);
}

.account__num {
  font-size: 20px;
  transform: translateY(2px);
}

.operation {
  color: rgba(67, 104, 246, 1);
  font-size: 14px;
  margin-left: 50px;
  transform: translateY(-3px);
}

.account--left {
  min-width: 300px;
}
</style>
